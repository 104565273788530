.button {
  height: 34px;
  padding: 8px 30px;
  background: #5d5ce2;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  text-transform: uppercase;
  color: #fff;
  border: none;
  margin-left: 10px;
  display: none;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  transition: filter 0.25s ease;
  &:hover {
    color: #fff;
    text-decoration: none;
    filter: brightness(1.1);
  }
  @media (min-width: 768px) {
    display: flex;
  }
}

.button--dark {
  background: #000;
  transition: filter 0.25s ease;
  &:hover {
    filter: brightness(1.1);
    text-decoration: none;
  }
}

.button--mobile {
  display: block;
  @media (min-width: 768px) {
    display: none;
  }
}

.button--sm {
  height: 28px;
  padding: 8px 25px;;
  font-size: 12px;
  line-height: 14px;
}