.CircularProgressbar {
  width: 80px;
  vertical-align: middle;
  position: absolute;
  top: -40px;
  left: calc(100% - 40px);
}
.CircularProgressbar-trail {
}
.CircularProgressbar-text {
}
.CircularProgressbar-background {
}

.CircularProgressbar .CircularProgressbar-path {
  stroke: #0BD855;
  stroke-linecap: round;
  transition: stroke-dashoffset 0.5s ease 0s;
}